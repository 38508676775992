import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	
	reload(e) {
		const path = window.location.pathname;
		const turbo_frames = document.querySelectorAll('turbo-frame');
		const current_frame = turbo_frames[turbo_frames.length - 1];
		if (current_frame && window.location.pathname.match(/\/groups\//)) {
			current_frame.src = window.location.pathname;
			current_frame.reload()
		}
	}
	
}
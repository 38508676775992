import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static values = { groupUrl: String, platform: String }

  connect() {
		if (this.platformValue === 'iOS') {
			window.webkit.messageHandlers.nativeApp.postMessage("contacts");
		} else if (this.platformValue === 'Android') {
			window.androidNativeApp.requestContacts();
		}
  }
	
	receive(e) {
		try {
			const groupUrl = this.groupUrlValue
			const contacts = JSON.parse(e.detail);
			const template = document.getElementById("contactList");
			const content = template.content.cloneNode(true);
			const contentContacts = content.querySelector(".group-members");
			function addPerson(contact, number, phoneType, email) {
				const personTemplate = document.getElementById("contactListItem");	
				const personContent = personTemplate.content.cloneNode(true);
				const listItem = personContent.querySelector(".group-member__list-item");
				let personName = `${contact.firstName} ${contact.lastName}`
				if (phoneType) {
					personName += ` (${phoneType})`
				}
				personContent.querySelector(".person").innerText = personName
				listItem.dataset.controller = "contacts";
				listItem.dataset.contactsNumberValue = number;
				listItem.dataset.contactsGroupUrlValue = groupUrl
				listItem.dataset.contactsNameValue = `${contact.firstName} ${contact.lastName}`
				listItem.dataset.contactSearchTarget = "name"
				if (email) {
					listItem.dataset.contactsEmailValue = email
				}
				contentContacts.appendChild(personContent)
			}
			for (const contact of contacts) {
				const numbers = Object.entries(contact.phoneNumbers);
				const emails = Object.entries(contact.emailAddresses);
				let email = null
				if (emails.length > 0) {
					email = emails[0][1]
				}
				if (contact.firstName.length === 0 && contact.lastName.length === 0) {
					continue
				} else if (numbers.length < 1) {
					continue
				} else if (numbers[0][0] === "#") {
					continue
				} else if (numbers.length === 1) {
					addPerson(contact, numbers[0][1], null, email)
				} else {
					for (const [phoneType, number] of numbers) {
						addPerson(contact, number, phoneType, email)
					}
				}
			}
			this.element.innerHTML = "";
			this.element.appendChild(content);
		} catch (err) {
			document.body.dispatchEvent(new CustomEvent('send', { detail: err.message }));
		}
	}
	
}
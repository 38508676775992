import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = [ "panel", "gear" ]

	toggle(e) {
		e.preventDefault();
		if (this.hasPanelTarget) {
			this.panelTarget.classList.toggle('group-options--hidden');
		}
		if (this.hasGearTarget) {
			this.gearTarget.classList.toggle('group-detail__settings-toggle--open');
		}
	}

  cleanLayout() {
		if (this.hasPanelTarget) {
			this.panelTarget.classList.add('group-options--hidden');
		}
		if (this.hasGearTarget) {
			this.gearTarget.classList.remove('group-detail__settings-toggle--open');
		}
  }
	
}
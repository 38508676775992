import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static values = { name: String, number: String, email: String, groupUrl: String }
	static targets = [ "addButton" ]
	
	addContact(e) {
		let formData = new FormData()
		formData.set("group_member[Name]", this.nameValue)
		formData.set("group_member[CellPhNbr]", this.numberValue)
		if (this.hasEmailValue) {
			formData.set("group_member[EmailAddr]", this.emailValue)
		}
		formData.set("group_member[Extension]", "")
		
		const options = {
			method: "POST",
			body: formData,
			credentials: 'same-origin',
			headers: {
				'Accept': 'application/json',
				'X-Requested-With': 'XMLHttpRequest',
				'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content
			}
		}
		fetch(this.groupUrlValue, options).then(response => response.json()).then(data => {
			if (data.error) {
				document.body.dispatchEvent(new CustomEvent('send', { detail: data.error }));
				return;
			}

			document.body.dispatchEvent(new CustomEvent('clearError'));
			
			this.addButtonTarget.dataset.action = ""
			this.addButtonTarget.innerText = "Added";
			this.addButtonTarget.disabled = true;
		})
	}
	
}
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static values = { iso: String }
	static targets = [ "datetime" ]
	
	connect() {
		const isoDate = new Date(this.isoValue);
		this.datetimeTarget.innerText = isoDate.toLocaleString("en-US");
	}
	
}
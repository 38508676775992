import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = ["text", "button"]
	
  copy(e) {
    this.textTarget.select()
    document.execCommand("copy")
		
		const el = this.buttonTarget
		const text = el.textContent
		el.textContent = "Copied to clipboard"
		
		setTimeout(() => el.textContent = text, 3000)
  }
}
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static values = { memberId: String, groupUrl: String }
	static targets = [ "addButton" ]
	
	addContact(e) {
		e.preventDefault()
		let formData = new FormData()
		formData.set("person[id]", this.memberIdValue)
		
		const options = {
			method: "POST",
			body: formData,
			credentials: 'same-origin',
			headers: {
				'Accept': 'application/json',
				'X-Requested-With': 'XMLHttpRequest',
				'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content
			}
		}
		fetch(this.groupUrlValue, options).then(response => response.json()).then(data => {
			if (data.error) {
				document.body.dispatchEvent(new CustomEvent('send', { detail: data.error }));
				return;
			}
			
			this.addButtonTarget.dataset.action = ""
			this.addButtonTarget.innerText = "Added";
			this.addButtonTarget.disabled = true;
		})
	}
	
}
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static values = { platform: String }
	static targets = [ "message" ]
	
	get overrideConfirm() {
		return this.platformValue === 'Android'
	}
	
	initialize() {
		if (this.overrideConfirm) {
			Turbo.setConfirmMethod((message, _el) => {
				const dialog = document.getElementById("turbo-confirm");
				dialog.querySelector("p").textContent = message
				dialog.showModal()
				return new Promise((resolve, reject) => {
					dialog.addEventListener("close", (e) => resolve(dialog.returnValue === "confirm"), { once: true })
				});
			});
		}
	}
	
	connect() {
	}
	
	receive(e) {
		this.messageTarget.textContent = e.detail;
		this.messageTarget.removeAttribute("hidden");
	}
	
	clear(e) {
		this.messageTarget.hidden = true;
	}
	
}
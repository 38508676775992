import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = [ "name", "searchField" ]
	
	filter(e) {
		if (this.hasSearchFieldTarget) {
			const query = this.searchFieldTarget.value
			if (query.length === 0) {
				this.nameTargets.forEach((target) => target.removeAttribute("hidden"))
				return;
			}
			this.nameTargets.forEach((target) => {
				if (!target.textContent.match(query)) {
					target.hidden = true
				} else {
					target.removeAttribute("hidden")
				}
			})
		}
	}
}
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static targets = [ "link" ]
	
	connect() {
		this.determineActionType();
	}
	
	determineActionType() {
		if (window.matchMedia("(min-width: 767px)").matches) {
			this.linkTargets.forEach(el => {
				el.dataset.turboFrame = el.dataset.thisFrame;
				el.dataset.turboAction = "replace";
			})
		} else {
			this.linkTargets.forEach(el => {
				delete el.dataset.turboFrame;
				el.dataset.turboAction = "advance";
			})
		}
	}
	
	adjustFrame(e) {
		if (window.matchMedia("(min-width: 767px)").matches) {
			const target = e.target;
			const groupFrame = document.querySelector(".group_detail > turbo-frame");
			groupFrame.id = target.dataset.thisFrame;
			document.querySelectorAll(".groups__list-item").forEach(el => el.classList.remove("group_item--open"));
			target.parentNode.classList.add("group_item--open");
		}
	}
	
}
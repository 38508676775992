import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static values = { platform: String }

  connect() {
		if (this.platformValue === 'Android') {
			window.androidNativeToolbar.toolbarChange();
		}
  }
	
}
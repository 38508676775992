import Rails from "@rails/ujs";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
	static values = { timeleft: Number }
	static targets = ["countdown", "start"]
	
	timeleftValueChanged() {
		if (this.timeleftValue == 0) {
			Rails.fire(this.startTarget, "submit")
		} else {
			setTimeout(() => this.tick(), 1000);
		}
	}
	
	tick() {
		this.timeleftValue--
		this.countdownTarget.textContent = this.timeleftValue
	}
	
  connect() {
		this.countdownTarget.textContent = this.timeleftValue
  }
}